export default (state, {
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  newFieldValue[`${instanceKey}_meta_updating`] = true;

  return newFieldValue;
};
