import {EVENT_PREFIX, getFormData} from '../../helpers';
import async from 'async';

let debounces = {};

export default (store, action, next) => {
  const debounce = debounces[action.instanceKey];
  const fields = Object.keys(action.formConfig.fields);

  const formData = getFormData(store.getState(), action.instanceKey, action.stateKey);

  const totalContext = {
    ...action.formContext,
    ...formData
  };

  const normalWork = [];
  const asyncWork = [];

  fields.forEach(key => {
    const fieldConfig = action.formConfig.fields[key];

    if (!fieldConfig) {
      return;
    }

    if (fieldConfig.hasOwnProperty('isVisible')) {
      if (fieldConfig.isVisible === false || fieldConfig.isVisible === true) {
        normalWork.push(() => {
          store.dispatch({
            type: EVENT_PREFIX + '_META_RESULT',
            field: key,
            isVisible: fieldConfig.isVisble
          });
        });
      } else {
        if (fieldConfig.isVisible.length === 2) {
          asyncWork.push(dispatch => fn => {
            fieldConfig.isVisble(totalContext, (err, isVisible) => {
              if (err) {
                return fn(err);
              }

              dispatch({
                field: key,
                instanceKey: action.instanceKey,
                type: EVENT_PREFIX + '_META_RESULT',
                isVisible
              });

              return fn();
            });
          });
        } else {
          normalWork.push(() => {
            store.dispatch({
              field: key,
              type: EVENT_PREFIX + '_META_RESULT',
              instanceKey: action.instanceKey,
              isVisible: fieldConfig.isVisible(totalContext)
            });
          });
        }
      }
    }

    if (fieldConfig.hasOwnProperty('isRequired')) {
      if (fieldConfig.isRequired === false || fieldConfig.isRequired === true) {
        normalWork.push(() => {
          store.dispatch({
            field: key,
            instanceKey: action.instanceKey,
            type: EVENT_PREFIX + '_META_RESULT',
            isRequired: fieldConfig.isRequired
          });
        });
      } else {
        if (fieldConfig.isRequired.length === 2) {
          asyncWork.push(dispatch => fn => {
            fieldConfig.isRequired(totalContext, (err, isRequired) => {
              if (err) {
                return fn(err);
              }

              dispatch({
                field: key,
                instanceKey: action.instanceKey,
                type: EVENT_PREFIX + '_META_RESULT',
                isRequired
              });

              return fn();
            });
          });
        } else {
          normalWork.push(() => {
            store.dispatch({
              field: key,
              type: EVENT_PREFIX + '_META_RESULT',
              instanceKey: action.instanceKey,
              isRequired: fieldConfig.isRequired(totalContext)
            });
          });
        }
      }
    }
    if (fieldConfig.hasOwnProperty('isDisabled')) {
      if (fieldConfig.isDisabled === false || fieldConfig.isDisabled === true) {
        normalWork.push(() => {
          store.dispatch({
            field: key,
            type: EVENT_PREFIX + '_META_RESULT',
            instanceKey: action.instanceKey,
            isDisabled: fieldConfig.isDisabled
          });
        });
      } else {
        if (fieldConfig.isDisabled.length === 2) {
          asyncWork.push(dispatch => fn => {
            fieldConfig.isDisabled(totalContext, (err, isDisabled) => {
              if (err) {
                return fn(err);
              }

              dispatch({
                field: key,
                type: EVENT_PREFIX + '_META_RESULT',
                instanceKey: action.instanceKey,
                isDisabled
              });

              return fn();
            });
          });
        } else {
          normalWork.push(() => {
            store.dispatch({
              field: key,
              type: EVENT_PREFIX + '_META_RESULT',
              instanceKey: action.instanceKey,
              isDisabled: fieldConfig.isDisabled(totalContext)
            });
          });
        }
      }
    }
  });

  if (normalWork.length > 0) {
    normalWork.forEach(w => w());
  }

  if (asyncWork.length > 0) {
    next(action);

    if (debounce) {
      clearTimeout(debounce);
    }

    debounces[action.instanceKey] = setTimeout(() => {
      const currentDebounce = debounce;

      // We decorate the work with a custom dispatch function.
      // It may be that a new 'run' is started, in that case, we don't want the dispatches of the previous run
      const decorate = work => work((action) => {
        if (currentDebounce !== debounce) {
          return;
        }
        store.dispatch(action);
      });

      async.parallel(asyncWork.map(decorate), decorate(dispatch => (err) => {
        if (err) {
          dispatch({
            type: EVENT_PREFIX + '_META_ERROR',
            error: err
          });
        }

        dispatch({
          type: EVENT_PREFIX + '_META_DONE'
        });
      }));
    }, 250);
  }
};
