import {EVENT_PREFIX} from '../helpers';

import onUpdateField from './handlers/onUpdateField';

import onUpdateData from './handlers/onUpdateData';

import onUpdatePreviousFormData from './handlers/onUpdatePreviousFormData';

import onTouchField from './handlers/onTouchField';
import onTouchAll from './handlers/onTouchAll';

import onSetList from './handlers/onSetList';

import onValidationDone from './handlers/onValidationDone';
import onValidationInit from './handlers/onValidationInit';
import onValidationError from './handlers/onValidationError';
import onValidationResult from './handlers/onValidationResult';

import onMetaDone from './handlers/onMetaDone';
import onMetaError from './handlers/onMetaError';
import onMetaInit from './handlers/onMetaInit';
import onMetaResult from './handlers/onMetaResult';

import onFormInit from './handlers/onFormInit';
import onFormInitDone from './handlers/onFormInitDone';
import onFormInitError from './handlers/onFormInitError';

import onResetTouched from './handlers/onResetTouched';
import onReset from './handlers/onReset';

import onSetSubmitting from './handlers/onSetSubmitting';
import onResetSubmitting from './handlers/onResetSubmitting';

export const formReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_PREFIX + '_UPDATE_FIELD':
      return onUpdateField(state, action);
    case EVENT_PREFIX + '_UPDATE_DATA':
      return onUpdateData(state, action);
    case EVENT_PREFIX + '_VALIDATION_INIT':
      return onValidationInit(state, action);
    case EVENT_PREFIX + '_VALIDATION_DONE':
      return onValidationDone(state, action);
    case EVENT_PREFIX + '_VALIDATION_ERROR':
      return onValidationError(state, action);
    case EVENT_PREFIX + '_VALIDATION_RESULT':
      return onValidationResult(state, action);
    case EVENT_PREFIX + '_META_INIT':
      return onMetaInit(state, action);
    case EVENT_PREFIX + '_META_DONE':
      return onMetaDone(state, action);
    case EVENT_PREFIX + '_META_ERROR':
      return onMetaError(state, action);
    case EVENT_PREFIX + '_META_RESULT':
      return onMetaResult(state, action);
    case EVENT_PREFIX + '_FORM_INIT':
      return onFormInit(state, action);
    case EVENT_PREFIX + '_FORM_INIT_DONE':
      return onFormInitDone(state, action);
    case EVENT_PREFIX + '_FORM_INIT_ERROR':
      return onFormInitError(state, action);
    case EVENT_PREFIX + '_SET_LIST':
      return onSetList(state, action);
    case EVENT_PREFIX + '_TOUCH_FIELD':
      return onTouchField(state, action);
    case EVENT_PREFIX + '_UPDATE_PREVIOUS_FORM_DATA':
      return onUpdatePreviousFormData(state, action);
    case EVENT_PREFIX + '_RESET_TOUCHED':
      return onResetTouched(state, action);
    case EVENT_PREFIX + '_TOUCH_ALL_FIELDS':
      return onTouchAll(state, action);
    case EVENT_PREFIX + '_RESET':
      return onReset(state, action);
    case EVENT_PREFIX + '_SET_SUBMITTING':
      return onSetSubmitting(state, action);
    case EVENT_PREFIX + '_RESET_SUBMITTING':
      return onResetSubmitting(state, action);
    default:
      return state;
  }
};

export updateField from './actions/updateField';
export updateData from './actions/updateData';
export updatePreviousFormData from './actions/updatePreviousFormData';
export resetTouched from './actions/resetTouched';
export setList from './actions/setList';
export validate from './actions/validate';
export formInit from './actions/formInit';
export touch from './actions/touch';
export touchAll from './actions/touchAll';
export reset from './actions/reset';
export meta from './actions/meta';
export setSubmitting from './actions/setSubmitting';
export resetSubmitting from './actions/resetSubmitting';
