import {EVENT_PREFIX} from '../../../helpers';

export default (
  {
    stateKey = 'form',
    instanceKey,
    list,
    name
  }) => (Object.assign({
  type: `${EVENT_PREFIX}_SET_LIST`
}, {instanceKey, stateKey, name, list}));
