export default (state, {
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  newFieldValue[`${instanceKey}_validating`] = false;

  return newFieldValue;
};
