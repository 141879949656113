export default (state, {
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  delete newFieldValue[`${instanceKey}_touched`];

  return newFieldValue;
};
