import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getFieldMessages, getMetaValue} from '../../redux/form/helpers';
import {withForm} from '../Form';

class FieldValidation extends Component {
  static propTypes = {
    errors: PropTypes.array,
    warnings: PropTypes.array,
    infos: PropTypes.array,
    hasErrors: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    hasInfos: PropTypes.bool,
    component: PropTypes.any.isRequired
  };

  render () {
    if (!this.props.hasErrors && !this.props.hasInfos && !this.props.hasWarnings) {
      return null;
    }

    const {
      component,
      ...rest
    } = this.props;

    return React.createElement(this.props.component, Object.assign({}, {
      ...rest
    }));
  }
}

export const mapStateToProps = (state, ownProps) => {
  let isVisible = getMetaValue(state, ownProps.name, 'isVisible', ownProps.formInstanceKey, ownProps.formStateKey);

  if (isVisible === undefined) {
    isVisible = true;
  }

  if (!isVisible) {
    return {
      errors: [],
      warnings: [],
      infos: [],
      hasErrors: false,
      hasWarnings: false,
      hasInfos: false
    };
  }

  const msgs = getFieldMessages(state, ownProps.name, ownProps.formInstanceKey);

  const errors = msgs.filter(m => m.severity === 'error').map(m => m.message);
  const warnings = msgs.filter(m => m.severity === 'warning').map(m => m.message);
  const infos = msgs.filter(m => m.severity === 'info').map(m => m.message);

  return {
    errors: errors.filter(msg => typeof msg === 'string'),
    warnings: warnings.filter(msg => typeof msg === 'string'),
    infos: infos.filter(msg => typeof msg === 'string'),
    hasErrors: errors.length > 0,
    hasWarnings: warnings.length > 0,
    hasInfos: infos.length > 0
  };
};

const mapDispatchToProps = () => ({});

export default withForm(connect(mapStateToProps, mapDispatchToProps)(FieldValidation));
