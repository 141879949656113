export default (state, {
  instanceKey,
  field,
  isRequired,
  isVisible,
  isDisabled
}) => {
  let newFieldValue = {
    ...state
  };

  if (isRequired !== undefined) {
    newFieldValue[`${instanceKey}_meta_${field}_isRequired`] = isRequired;
  }

  if (isVisible !== undefined) {
    newFieldValue[`${instanceKey}_meta_${field}_isVisible`] = isVisible;
  }

  if (isDisabled !== undefined) {
    newFieldValue[`${instanceKey}_meta_${field}_isDisabled`] = isDisabled;
  }

  return newFieldValue;
};
