import {EVENT_PREFIX} from '../../../helpers';

export default (
  {
    stateKey = 'form',
    instanceKey,
    formConfig,
    formContext
  }) => (Object.assign({
  type: `${EVENT_PREFIX}_FORM_INIT`
}, {instanceKey, stateKey, formContext, formConfig}));
