export default (state, {
  field,
  value,
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  const current = newFieldValue[`${instanceKey}_touched`] || [];

  // No change needed
  if (current.indexOf(field) > -1) {
    return state;
  }

  newFieldValue[`${instanceKey}_touched`] = [
    ...current,
    field
  ];

  return newFieldValue;
};
