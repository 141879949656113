import {EVENT_PREFIX} from '../../../helpers';

export default (
  {
    instanceKey,
    data,
    noOverwrite
  }) => (Object.assign({
  type: `${EVENT_PREFIX}_UPDATE_DATA`
}, {instanceKey, data, noOverwrite}));
