export default (state, {
  instanceKey,
  formConfig
}) => {
  let newFieldValue = {
    ...state
  };

  const current = newFieldValue[`${instanceKey}_touched`] || [];

  Object.keys(formConfig.fields).forEach(field => {
    if (current.indexOf(field) === -1) {
      current.push(field);
    }
  });

  newFieldValue[`${instanceKey}_touched`] = [
    ...current
  ];

  return newFieldValue;
};
