export default (state, {
  instanceKey,
  field,
  ruleIndex,
  isValid,
  message,
  severity
}) => {
  let newFieldValue = {
    ...state
  };

  if (typeof isValid !== 'boolean') {
    throw new Error(`Rule for ${field} with index ${ruleIndex} does not return a boolean value for isValid`);
  }

  newFieldValue[`${instanceKey}_validation_${field}_isValid_${ruleIndex}`] = isValid;
  newFieldValue[`${instanceKey}_validation_${field}_message_${ruleIndex}`] = message;
  newFieldValue[`${instanceKey}_validation_${field}_severity_${ruleIndex}`] = severity;

  return newFieldValue;
};
