import {EVENT_PREFIX} from '../../../helpers';

export default (
  {
    field,
    instanceKey,
    value
  }) => (Object.assign({
  type: `${EVENT_PREFIX}_UPDATE_FIELD`
}, {field, instanceKey, value}));
