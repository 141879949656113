import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateField,
  validate,
  meta,
  touch
} from '../../redux/form';

import {getFieldValue, getMetaValue, getInstanceValue, getList} from '../../redux/form/helpers';

import FormContext from '../Form/context';

import {mapStateToProps as validationMapStateToProps} from '../FieldValidation';

class Field extends Component {
  static propTypes = {
    listLabels: PropTypes.arrayOf(PropTypes.string),
    listValues: PropTypes.arrayOf(PropTypes.string),
    setValue: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    component: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    value: PropTypes.any,
    noAutoHide: PropTypes.bool
  };

  static defaultProps = {
    noAutoHide: false,
    formStateKey: 'form'
  };

  render () {
    if (!this.props.visible && this.props.noAutoHide === false) {
      return null;
    }

    const {
      component,
      // eslint-disable-next-line
      formStateKey,
      // eslint-disable-next-line
      formInstanceKey,
      // eslint-disable-next-line
      formContext,
      // eslint-disable-next-line
      formConfig,
      // eslint-disable-next-line
      list,
      // eslint-disable-next-line
      onInit,
      visible,
      listValues,
      listLabels,
      ...rest
    } = this.props;

    return React.createElement(this.props.component, Object.assign({}, {
      list: listValues.map((value, index) => ({
        value,
        label: listLabels[index]
      })),
      ...rest
    }));
  }
}

const ConnectedField = connect(
  (state, ownProps) => {
    const newValue = getFieldValue(state, ownProps.name, ownProps.formInstanceKey, ownProps.formStateKey);
    let formInitializing = getInstanceValue(state, 'initializing', ownProps.formInstanceKey, ownProps.formStateKey);

    const isDisabled = getMetaValue(state, ownProps.name, 'isDisabled', ownProps.formInstanceKey, ownProps.formStateKey);
    const isRequired = getMetaValue(state, ownProps.name, 'isRequired', ownProps.formInstanceKey, ownProps.formStateKey);
    let isVisible = getMetaValue(state, ownProps.name, 'isVisible', ownProps.formInstanceKey, ownProps.formStateKey);

    if (formInitializing === undefined) {
      formInitializing = false;
    }

    if (isVisible === undefined) {
      isVisible = true;
    }

    let listValues = [];
    let listLabels = [];

    if (ownProps.list) {
      const list = getList(state, ownProps.list, ownProps.formInstanceKey, ownProps.formStateKey);

      listValues = list.map(i => i.value);
      listLabels = list.map(i => i.label);
    }

    const validation = validationMapStateToProps(state, ownProps);

    return {
      listValues,
      listLabels,
      disabled: formInitializing || isDisabled,
      required: isRequired,
      visible: isVisible,
      value: newValue,
      ...validation
    };
  },
  (dispatch, ownProps) => {
    return {
      updateField: (name, value) => {
        dispatch(updateField({
          field: name,
          value,
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey
        }));
        dispatch(meta({
          field: name,
          value,
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig,
          formContext: ownProps.formContext
        }));
      },
      touch: () => {
        dispatch(touch({
          field: ownProps.name,
          instanceKey: ownProps.formInstanceKey
        }));
      },
      validate: (onlyTouched = true) => {
        dispatch(touch({
          field: ownProps.name,
          instanceKey: ownProps.formInstanceKey
        }));
        dispatch(validate({
          onlyTouched,
          field: ownProps.name,
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig,
          formContext: ownProps.formContext
        }));
      },
      setValue: (value) => {
        dispatch(updateField({
          field: ownProps.name,
          value,
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey
        }));
        dispatch(meta({
          field: ownProps.name,
          value,
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig,
          formContext: ownProps.formContext
        }));
      }
    };
  })(Field);

class FieldWrapper extends Component {
  static contextType = FormContext;

  render () {
    const props = {...this.props};

    props.formStateKey = this.context.stateKey;
    props.formInstanceKey = this.context.instanceKey;
    props.formConfig = this.context.formConfig;
    props.formContext = this.context.formContext;

    return <ConnectedField {...props} />;
  }
}

export default FieldWrapper;
