export default (state, {
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  console.log('in onFormInit', instanceKey);
  newFieldValue[`${instanceKey}_initializing`] = true;
  newFieldValue[`${instanceKey}_isSubmitting`] = false;

  return newFieldValue;
};
