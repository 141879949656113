export default (state, {
  data,
  instanceKey,
  noOverwrite
}) => {
  let newFieldValue = {
    ...state
  };

  const keys = Object.keys(data);

  keys.forEach(field => {
    if (noOverwrite && newFieldValue.hasOwnProperty(`${instanceKey}_data_${field}`)) {
      return;
    }
    newFieldValue[`${instanceKey}_data_${field}`] = data[field];
  });

  return newFieldValue;
};
