export default (state, {
  field,
  value,
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  newFieldValue[`${instanceKey}_data_${field}`] = value;

  return newFieldValue;
};
