export default (state, {
  instanceKey,
  error
}) => {
  let newFieldValue = {
    ...state
  };

  let msg = error;

  if (typeof error !== 'string') {
    if (error.hasOwnProperty('message') && typeof error.message === 'string') {
      msg = error.message;
    } else {
      msg = error.toString();
    }
  }

  newFieldValue[`${instanceKey}_meta_error`] = msg;

  return newFieldValue;
};
