import {
  updateData,
  validate as validateAction,
  setList as setListAction,
  meta,
  updatePreviousFormData,
  resetTouched,
  resetSubmitting
} from '../..';

import async from 'async';
import {EVENT_PREFIX, getFormData} from '../../helpers';

export default (store, action, next) => {
  const state = store.getState();

  if (!state.hasOwnProperty(action.stateKey)) {
    return;
  }

  store.dispatch(resetSubmitting(action.instanceKey, action.stateKey));

  // Reset touched fields
  store.dispatch(resetTouched({
    instanceKey: action.instanceKey
  }));

  // Specify first parameter for the onInit methods; idea is a sort of context for the onInit with a setValue prop to set form values
  const baseContext = {
    ...getFormData(state, action.instanceKey, action.stateKey)
  };

  let asyncWork = [];
  let work = [];

  const setValues = (data, noOverwrite = false) => {
    store.dispatch(updateData({
      instanceKey: action.instanceKey,
      data,
      noOverwrite
    }));
  };

  const setDefaultValues = (data) => {
    return setValues(data, true);
  };

  const setList = (name, list) => {
    store.dispatch(setListAction({
      stateKey: action.stateKey,
      instanceKey: action.instanceKey,
      name,
      list
    }));
  };

  const validate = () => {
    store.dispatch(validateAction({
      instanceKey: action.instanceKey,
      formConfig: action.formConfig,
      formContext: action.formContext
    }));
  };

  Object.keys(action.formConfig.fields).forEach(field => {
    const onInit = action.formConfig.fields[field].onInit;

    if (onInit === undefined) {
      return;
    }

    if (onInit.length === 2) {
      asyncWork.push((fn) => {
        onInit({
          ...baseContext,
          setValues,
          setDefaultValues,
          validate,
          setList
        }, fn);
      });
    } else {
      work.push(() => {
        onInit({
          ...baseContext,
          setValues,
          setDefaultValues,
          validate,
          setList
        });
      });
    }
  });

  work.forEach(w => w());

  const formOnInit = action.formConfig.onInit;

  if (asyncWork.length > 0) {
    // Notify that takes longer (the onInit phase)
    next(action);
    async.parallel(asyncWork, (err) => {
      if (err) {
        store.dispatch({
          instanceKey: action.instanceKey,
          type: EVENT_PREFIX + '_FORM_INIT_ERROR',
          error: err
        });
        return;
      }

      if (typeof formOnInit === 'function') {
        if (formOnInit.length === 2) {
          formOnInit({
            ...baseContext,
            setValues,
            setDefaultValues,
            validate,
            setList
          }, (err) => {
            if (err) {
              store.dispatch({
                instanceKey: action.instanceKey,
                type: EVENT_PREFIX + '_FORM_INIT_ERROR',
                error: err
              });
            }

            store.dispatch({
              instanceKey: action.instanceKey,
              type: EVENT_PREFIX + '_FORM_INIT_DONE'
            });

            store.dispatch(meta({
              stateKey: action.stateKey,
              instanceKey: action.instanceKey,
              formContext: action.formContext,
              formConfig: action.formConfig
            }));

            store.dispatch(updatePreviousFormData({
              instanceKey: action.instanceKey,
              data: getFormData(store.getState(), action.instanceKey, action.stateKey)
            }));
          });
        } else {
          formOnInit({
            ...baseContext,
            setValues,
            setDefaultValues,
            validate,
            setList
          });

          store.dispatch({
            instanceKey: action.instanceKey,
            type: EVENT_PREFIX + '_FORM_INIT_DONE'
          });
          store.dispatch(meta({
            stateKey: action.stateKey,
            instanceKey: action.instanceKey,
            formContext: action.formContext,
            formConfig: action.formConfig
          }));
          store.dispatch(updatePreviousFormData({
            instanceKey: action.instanceKey,
            data: getFormData(store.getState(), action.instanceKey, action.stateKey)
          }));
        }
      } else {
        store.dispatch({
          instanceKey: action.instanceKey,
          type: EVENT_PREFIX + '_FORM_INIT_DONE'
        });
        store.dispatch(meta({
          stateKey: action.stateKey,
          instanceKey: action.instanceKey,
          formContext: action.formContext,
          formConfig: action.formConfig
        }));
        store.dispatch(updatePreviousFormData({
          instanceKey: action.instanceKey,
          data: getFormData(store.getState(), action.instanceKey, action.stateKey)
        }));
      }
    });
  } else {
    if (typeof formOnInit === 'function') {
      if (formOnInit.length === 2) {
        // Notify that takes longer (the onInit phase)
        next(action);
        formOnInit({
          ...baseContext,
          setValues,
          setDefaultValues,
          validate,
          setList
        }, (err) => {
          if (err) {
            store.dispatch({
              instanceKey: action.instanceKey,
              type: EVENT_PREFIX + '_FORM_INIT_ERROR',
              error: err
            });
          }

          store.dispatch({
            instanceKey: action.instanceKey,
            type: EVENT_PREFIX + '_FORM_INIT_DONE'
          });
          store.dispatch(meta({
            stateKey: action.stateKey,
            instanceKey: action.instanceKey,
            formContext: action.formContext,
            formConfig: action.formConfig
          }));
          store.dispatch(updatePreviousFormData({
            instanceKey: action.instanceKey,
            data: getFormData(store.getState(), action.instanceKey, action.stateKey)
          }));
        });
      } else {
        formOnInit({
          ...baseContext,
          setValues,
          setDefaultValues,
          validate,
          setList
        });
        store.dispatch(meta({
          stateKey: action.stateKey,
          instanceKey: action.instanceKey,
          formContext: action.formContext,
          formConfig: action.formConfig
        }));
        store.dispatch(updatePreviousFormData({
          instanceKey: action.instanceKey,
          data: getFormData(store.getState(), action.instanceKey, action.stateKey)
        }));
      }
    }
  }
};
