export default (state, {
  instanceKey,
  name,
  list
}) => {
  let newFieldValue = {
    ...state
  };

  if (!Array.isArray(list)) {
    throw new Error('List is not an array');
  }

  list.forEach(l => {
    if (!l.hasOwnProperty('value')) {
      throw new Error('Item in list does not have a value property.');
    }

    if (!l.hasOwnProperty('label')) {
      throw new Error('Item in list does not have a label property.');
    }
  });

  newFieldValue[`${instanceKey}_list_${name}`] = list;

  return newFieldValue;
};
