export const EVENT_PREFIX = 'NEXIOS_FORM';

export const getInstanceValue = (state, key, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  const dataKey = `${instanceKey}_${key}`;

  return state[stateKey][dataKey];
};

export const getMetaValue = (state, field, key, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  const dataKey = `${instanceKey}_meta_${field}_${key}`;

  return state[stateKey][dataKey];
};

export const getFieldValue = (state, field, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  let dataKey = `${instanceKey}_data_${field}`;

  return state[stateKey][dataKey];
};

export const getList = (state, list, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  let dataKey = `${instanceKey}_list_${list}`;

  return state[stateKey][dataKey] || [];
};

export const getTouchedFields = (state, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  let dataKey = `${instanceKey}_touched`;

  return state[stateKey][dataKey] || [];
};

export const getFieldsMessages = (state, fields, instanceKey, stateKey = 'form') => {
  const result = {};

  fields.forEach(field => {
    result[field] = getFieldMessages(state, field, instanceKey, stateKey);
  });

  return result;
};

const getIndex = str => {
  const split = str.split('_');
  return split[split.length - 1];
};

export const getFieldMessages = (state, field, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  const validationMessageKeyPrefix = `${instanceKey}_validation_${field}_message_`;

  const formData = state[stateKey];

  return Object
    .keys(formData)
    .filter(key => key.startsWith(validationMessageKeyPrefix))
    .filter((key) => formData[`${instanceKey}_validation_${field}_isValid_${getIndex(key)}`] === false)
    .map((key) => {
      return {
        message: formData[key],
        severity: formData[`${instanceKey}_validation_${field}_severity_${getIndex(key)}`]
      };
    });
};

export const getFieldValidation = (state, field, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return;
  }

  const validationIsValidKeyPrefix = `${instanceKey}_validation_${field}_isValid_`;
  const validationMessageKeyPrefix = `${instanceKey}_validation_${field}_message_`;

  const formData = state[stateKey];

  const result = {};

  Object.keys(formData).forEach(key => {
    if (key.startsWith(validationIsValidKeyPrefix)) {
      const index = getIndex(key);

      if (!result.hasOwnProperty(field)) {
        result[field] = [];
      }

      if (!result[field].hasOwnProperty(index)) {
        result[field][index] = {};
      }

      result[field][index].isValid = formData[key];
    }

    if (key.startsWith(validationMessageKeyPrefix)) {
      const index = key.substr(validationMessageKeyPrefix.length);

      if (!result.hasOwnProperty(field)) {
        result[field] = [];
      }

      if (!result[field].hasOwnProperty(index)) {
        result[field][index] = {};
      }

      result[field][index].message = formData[key];
    }
  });

  return result;
};

export const isValid = (state, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return false;
  }

  const formState = state[stateKey];

  const validationProps = Object.keys(formState).filter(key => /_validation_.*_isValid/.test(key));
  let isValid = true;

  validationProps.forEach(prop => {
    if (!formState[prop]) {
      isValid = false;
    }
  });

  return isValid;
};

export const isSubmitting = (state, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return false;
  }

  const formState = state[stateKey];

  return !!formState[`${instanceKey}_isSubmitting`];
};

export const getFormData = (state, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return {};
  }

  let dataKeyPrefix = `${instanceKey}_data_`;

  let formData = state[stateKey];

  let result = {};

  Object.keys(formData).forEach(key => {
    if (key.startsWith(dataKeyPrefix)) {
      result[key.substr(dataKeyPrefix.length)] = formData[key];
    }
  });

  return result;
};

export const getPreviousFormData = (state, instanceKey, stateKey = 'form') => {
  if (!state.hasOwnProperty(stateKey)) {
    return {};
  }

  let dataKeyPrefix = `${instanceKey}_previous_`;

  let formData = state[stateKey];

  let result = {};

  Object.keys(formData).forEach(key => {
    if (key.startsWith(dataKeyPrefix)) {
      result[key.substr(dataKeyPrefix.length)] = formData[key];
    }
  });

  return result;
};
