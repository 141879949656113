import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {validate, formInit, touchAll} from '../../redux/form/reducer';
import {getInstanceValue} from '../../redux/form/helpers';

import FormContext from './context';

class Form extends Component {
  static propTypes = {
    formStateKey: PropTypes.string.isRequired,
    formInstanceKey: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    formConfig: PropTypes.object,
    formContext: PropTypes.object,
    disabled: PropTypes.bool,
    init: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount () {
    // Call the init.
    this.props.init();
  }

  submit (e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.submit();
  }

  render () {
    const {
      formStateKey,
      formInstanceKey,
      formConfig,
      formContext,
      ...rest
    } = this.props;

    return <FormContext.Provider
      value={{
        stateKey: formStateKey,
        instanceKey: formInstanceKey,
        formConfig: formConfig,
        formContext: formContext
      }}>
      <form {...rest} onSubmit={this.submit}>
        <fieldset disabled={this.props.disabled}>
          {this.props.children}
        </fieldset>
      </form>
    </FormContext.Provider>;
  }
}

const ConnectedForm = connect(
  (state, ownProps) => {
    let initializing = getInstanceValue(state, 'initializing', ownProps.formInstanceKey, ownProps.formStateKey);

    if (initializing === undefined) {
      initializing = false;
    }

    const disabled = initializing;

    return {
      initializing,
      disabled
    };
  },
  (dispatch, ownProps) => {
    return {
      submit: () => {
        dispatch(touchAll({
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig
        }));

        dispatch(validate({
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig,
          formContext: ownProps.formContext,
          onValid: ownProps.formConfig.onSubmit
        }));
      },
      init: () => {
        dispatch(formInit({
          stateKey: ownProps.formStateKey,
          instanceKey: ownProps.formInstanceKey,
          formConfig: ownProps.formConfig,
          formContext: ownProps.formContext
        }));
      }
    };
  }
)(Form);

ConnectedForm.defaultProps = {
  formConfig: {fields: {}},
  formContext: {},
  formStateKey: 'form'
};

export default ConnectedForm;

export const withForm = WrappedComponent => {
  return class extends Component {
    static contextType = FormContext;

    render () {
      const {stateKey, instanceKey, formConfig, formContext} = this.context;
      const childProps = {
        formStateKey: stateKey,
        formInstanceKey: instanceKey,
        formConfig,
        formContext
      };

      return <WrappedComponent {...childProps} {...this.props} />;
    }
  };
};
