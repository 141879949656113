export default (state, {
  instanceKey
}) => {
  let newFieldValue = {
    ...state
  };

  const keys = Object.keys(newFieldValue);

  keys.forEach(field => {
    if (field.startsWith(instanceKey)) {
      delete newFieldValue[field];
    }
  });

  return newFieldValue;
};
