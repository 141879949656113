import {EVENT_PREFIX} from '../helpers';
import validateInit from './validateInit';
import metaInit from './metaInit';
import formInit from './formInit';

const VALIDATE_INIT = EVENT_PREFIX + '_VALIDATE_INIT';
const META_INIT = EVENT_PREFIX + '_META_INIT';
const FORM_INIT = EVENT_PREFIX + '_FORM_INIT';

export default (store) => next => action => {
  if ([
    VALIDATE_INIT,
    META_INIT,
    FORM_INIT
  ].indexOf(action.type) === -1) {
    return next(action);
  }

  if (action.type === VALIDATE_INIT) {
    validateInit(store, action, next);
  }

  if (action.type === META_INIT) {
    metaInit(store, action, next);
  }

  if (action.type === FORM_INIT) {
    formInit(store, action, next);
  }
};
