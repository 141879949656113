import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getFieldsMessages, getMetaValue} from '../../redux/form/helpers';
import {withForm} from '../Form';

class FormValidation extends Component {
  static propTypes = {
    hasErrors: PropTypes.bool,
    hasWarnings: PropTypes.bool,
    hasInfos: PropTypes.bool,
    component: PropTypes.any.isRequired
  };

  render () {
    const {
      component,
      ...rest
    } = this.props;

    return React.createElement(this.props.component, Object.assign({}, {
      ...rest
    }));
  }
}

const mapStateToProps = (state, ownProps) => {
  const fields = Object.keys(ownProps.formConfig.fields);

  const msgs = getFieldsMessages(
    state,
    fields,
    ownProps.formInstanceKey
  );

  let hasErrors = false;
  let hasWarnings = false;
  let hasInfos = false;

  fields.forEach(field => {
    if (!msgs.hasOwnProperty(field)) {
      return;
    }

    let isVisible = getMetaValue(state, field, 'isVisible', ownProps.formInstanceKey, ownProps.formStateKey);

    if (isVisible === undefined) {
      isVisible = true;
    }

    // Ignore invisible fields.
    if (!isVisible) {
      return;
    }

    hasErrors = hasErrors || (msgs[field].find(m => m.severity === 'error') !== undefined);
    hasWarnings = hasWarnings || (msgs[field].find(m => m.severity === 'warning') !== undefined);
    hasInfos = hasInfos || (msgs[field].find(m => m.severity === 'info') !== undefined);
  });

  return {
    hasErrors,
    hasWarnings,
    hasInfos
  };
};

const mapDispatchToProps = () => ({});

export default withForm(connect(mapStateToProps, mapDispatchToProps)(FormValidation));
